.cloudinary-upload-widget {
  position: relative;
  width: 6.5em;
  height: 6.5em;
  border: 2px grey solid;
  border-radius: 5px;
}
.cloudinary-overlay-widget:hover {
  opacity: 1;
  cursor: pointer;
}
.cloudinary-overlay-widget {
  opacity: 0.25;
  z-index: 2;
  width: 100%;
  height: 100%;
  font-size: 4em;
  color: darkgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}
