/* General map elements styles with some enforsing limitations of map items view */
.pointing-segment:hover {
  opacity: 1;
  scale: 1.01;
  background-color: white !important;
  z-index: 5;
}
.pointing-segment {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 190px;
  min-height: 40px;
  user-select: none;
  transform: translate(5px, -50%);
  word-wrap: break-word;
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.pointing-segment.ui.segment {
  padding: 6px;
  cursor: default;
  font-weight: 400;
}
.pointing-segment.ui.pointing.label:before {
  height: 1.5em;
  width: 1.5em;
}
.pointing-segment::before {
  border-width: 0 0 1px 1px;
  /* prevents overlapping with content */
  border-radius: 0 30px 0 0;
  border-color: inherit;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  bottom: auto;
  right: auto;
  top: 50%;
  left: 0;
  position: absolute;
  content: '';
  background-image: none;
  z-index: 2;
  width: 0.6666em;
  height: 0.6666em;
}

.pointing-segment .title {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  width: 100%;
}
.pointing-segment .title img {
  max-width: 2.2em !important;
  max-height: 2.2em !important;
}

/* Alternative implementation of pointing label on map - not clear enough */
/* .pointing-label-right-side {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}
.pointing-label-right-side::before {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid white;
  border-bottom: 10px solid transparent;
  transform: translateY(-50%);
  content: '';
  top: 50%;
  right: 100%;
  position: absolute;
} */
