/* Main elements positioning */

#home {
  height: 100vh;
  width: 100%;
}
#new-content-widget {
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 2;
  margin-top: 0 !important;
}
